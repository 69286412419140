/*
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './contact-form3.css';

const ContactForm3 = (props) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    termsAccepted: true,
  });

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;

    // Custom validation example for the phone number
    if (id === 'phone' && (value.length > 10 || value < 0)) {
      return; // Prevent invalid phone numbers
    }

    setFormData({
      ...formData,
      [id]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form submission logic goes here
    console.log('Form submitted:', formData);
  };

  return (
    <div className="contact-form3-contact9 thq-section-padding">
      <div className="thq-flex-row thq-section-max-width contact-form3-max-width">
        <img
          alt={props.ImageAlt}
          src={props.imagesvg}
          className="contact-form3-image1 thq-img-ratio-4-3"
        />
        <div className="contact-form3-content thq-flex-column">
          <div className="contact-form3-section-title thq-card">
            <div className="contact-form3-content1">
              <h3 className="thq-heading-2">{props.heading1}</h3>
              <span className="thq-body-large">{props.content1}</span>
            </div>
          </div>
          <form className="thq-card" onSubmit={handleSubmit}>
            <div className="contact-form3-input">
              <label htmlFor="name" className="thq-body-small">
                Name
              </label>
              <input
                type="text"
                id="name"
                placeholder="Name"
                className="thq-input"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="contact-form3-input1">
              <label htmlFor="email" className="thq-body-small">
                Email
              </label>
              <input
                type="email"
                id="email"
                required
                placeholder="Email"
                className="thq-input"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="contact-form3-input2">
              <label htmlFor="phone" className="thq-body-small">
                Mobile No.
              </label>
              <input
                type="number"
                id="phone"
                required
                placeholder="Contact No."
                className="thq-input"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="contact-form3-container">
              <label
                htmlFor="message"
                className="thq-body-small"
              >
                Message
              </label>
              <textarea
                id="message"
                rows="3"
                placeholder="Enter your message"
                className="thq-input"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="contact-form3-checkbox">
              <input
                type="checkbox"
                id="termsAccepted"
                checked={formData.termsAccepted}
                required
                className="thq-checkbox"
                onChange={handleChange}
              />
              <label
                htmlFor="termsAccepted"
                className="contact-form3-text5 thq-body-small"
              >
                I accept the Terms
              </label>
            </div>
            <button
              type="submit"
              className="contact-form3-button thq-button-filled"
            >
              <span className="thq-body-small">{props.action}</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

ContactForm3.defaultProps = {
  imagesvg:
    'https://images.unsplash.com/photo-1461749280684-dccba630e2f6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDI0MzY4N3w&ixlib=rb-4.0.3&q=80&w=1080',
  content1: 'Don’t Hesitate to contact us for any kind of information.',
  ImageAlt: 'Image1',
  content2: 'Get in touch with us',
  heading1: 'Contact us',
  action: 'Submit',
};

ContactForm3.propTypes = {
  imagesvg: PropTypes.string,
  content1: PropTypes.string,
  ImageAlt: PropTypes.string,
  content2: PropTypes.string,
  heading1: PropTypes.string,
  action: PropTypes.string,
};

export default ContactForm3;
*/
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './contact-form3.css';

const ContactForm3 = (props) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    alt: '',
    message: '',
    termsAccepted: false,
  });

  const [submitMessage, setSubmitMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'phone' && (value.length > 10 || value < 0)) {
      return; // Prevent invalid phone numbers
    }
    if (name === 'alt' && (value.length > 10 || value < 0)) {
      return; // Prevent invalid phone numbers
    }
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://www.amitworldacademy.com/save_data.php', formData);
      console.log('Success:', response.data);
      setSubmitMessage('Form submitted successfully!');
      setFormData({
        name: '',
        email: '',
        phone: '',
        alt: '',
        message: '',
        termsAccepted: false,
      });
    } catch (error) {
      console.error('Error:', error);
      setSubmitMessage('Error submitting the form. Please try again.');
    }
  };

  return (
    <div className="contact-form3-contact9 thq-section-padding">
      <div className="thq-flex-row thq-section-max-width contact-form3-max-width">
        <img
          alt={props.ImageAlt}
          src={props.ImageSrc}
          className="contact-form3-image1 "
        />
        <div className="contact-form3-content thq-flex-column">
          <div className="contact-form3-section-title thq-card">
            <div className="contact-form3-content1">
              <h3 className="thq-heading-2">{props.heading1}</h3>
              <span className="thq-body-large">{props.content1}</span>
            </div>
          </div>
          <form className="thq-card" onSubmit={handleSubmit}>
            <div className="contact-form3-input">
              <label htmlFor="name" className="thq-body-small">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                className="thq-input"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="contact-form3-input1">
              <label htmlFor="email" className="thq-body-small">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                required
                placeholder="Email"
                className="thq-input"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="contact-form3-input2">
              <label htmlFor="phone" className="thq-body-small">Mobile No.</label>
              <input
                type="number"
                id="phone"
                name="phone"
                required
                placeholder="Contact No."
                className="thq-input"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="contact-form3-input3">
              <label htmlFor="alt" className="thq-body-small">Alternate Mobile No.</label>
              <input
                type="number"
                id="alt"
                name="alt"
                required
                placeholder="Alternate Contact No."
                className="thq-input"
                value={formData.phone2}
                onChange={handleChange}
              />
            </div>
            <div className="contact-form3-container">
              <label htmlFor="message" className="thq-body-small">Message</label>
              <textarea
                id="message"
                name="message"
                rows="3"
                placeholder="Enter your message"
                className="thq-input"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="contact-form3-checkbox">
              <input
                type="checkbox"
                id="termsAccepted"
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleChange}
                className="thq-checkbox"
              />
              <label htmlFor="termsAccepted" className="contact-form3-text5 thq-body-small">
                I accept the Terms and Condotions.
              </label>
            </div>
            <button
              type="submit"
              className="contact-form3-button thq-button-filled"
            >
              <span className="thq-body-small">{props.action}</span>
            </button>
          </form>
          {submitMessage && <p className="submit-message">{submitMessage}</p>}
        </div>
      </div>
    </div>
  );
};

ContactForm3.defaultProps = {
  ImageSrc: '/contact.jpg',
  content1: 'Don’t Hesitate to contact us for any kind of information.',
  ImageAlt: 'Image1',
  content2: 'Get in touch with us',
  heading1: 'Contact us',
  action: 'Submit',
};

ContactForm3.propTypes = {
  ImageAlt: PropTypes.string.isRequired,
  ImageSrc: PropTypes.string.isRequired,
  heading1: PropTypes.string.isRequired,
  content1: PropTypes.string.isRequired,
  content2: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,

};

export default ContactForm3;
