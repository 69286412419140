/*
import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Legal from './views/legal'
import Contact from './views/contact'
import Home from './views/home'
import Courses from './views/courses'
import About from './views/about'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Legal} exact path="/legal" />
        <Route component={Contact} exact path="/contact" />
        <Route component={Home} exact path="/" />
        <Route component={Courses} exact path="/courses" />
        <Route component={About} exact path="/about" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
*/
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './style.css';
import Legal from './views/legal';
import Contact from './views/contact';
import Home from './views/home';
import Courses from './views/courses';
import About from './views/about';
import NotFound from './views/not-found';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/legal" component={Legal} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/" component={Home} />
        <Route exact path="/courses" component={Courses} />
        <Route exact path="/about" component={About} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};


ReactDOM.render(<App />, document.getElementById('app'));
