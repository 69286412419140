import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar81 from '../components/navbar81'
import Head1 from '../components/head1'
import CTA1 from '../components/cta1'
import Features211 from '../components/features211'
import Features19 from '../components/features19'
import Features20 from '../components/features20'
import Features191 from '../components/features191'
import Features201 from '../components/features201'
import Features192 from '../components/features192'
import Features22 from '../components/features22'
import Pricing8 from '../components/pricing8'
import Footer1 from '../components/footer1'
import './courses.css'

const Courses = (props) => {
  return (
    <div className="courses-container">
      <Helmet>
        <title>Courses - Amit World Academy</title>
        <meta property="og:title" content="Courses - Amit World Academy" />
      </Helmet>
      <Navbar81 rootClassName="navbar81-root-class-name1"></Navbar81>
      <Head1 rootClassName="head1-root-class-name"></Head1>
      <CTA1></CTA1>
      <Features211
        feature1Title="Real Estate"
        feature1Description="This course covers buying, selling, investing, property management, market analysis, and legal aspects."
        feature2Title="Digital Assets"
        feature2Description="This course explores cryptocurrencies, NFTs, blockchain technology, digital investment securities, "
        feature3Title="Business Mastery"
        feature3Description="This course focuses on strategy, leadership, time management, financial management, & scaling business."
        feature4Title="Sale & Marketing"
        feature4Description="This course covers sales techniques, digital marketing, branding, consumer behavior, and market strategies." 
      ></Features211>
      <Features19
        cost="INR 9,997"
        cost2="INR 97,000"
        feature1Title="Basic Real Estate Courses"
        feature1ImageSrc="https://images.unsplash.com/photo-1558036117-15d82a90b9b1?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxSZWFsJTIwRXN0YXRlfGVufDB8fHx8MTcyMDMyNzMxOXww&amp;ixlib=rb-4.0.3&amp;w=1400"
      ></Features19>
      <Features20
        cost2="INR 9,997"
        description2="2 Classes Related to Cryptocurrency and blockchain"
        description3="One month training on treading and blockchain"
        description4="by Amit Kumar Jha"
        feature1Title="Cryptocurrency and Blockchain"
        feature1ImageSrc="https://images.unsplash.com/photo-1640833906651-6bd1af7aeea3?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEzfHxjcnlwdG98ZW58MHx8fHwxNzIxMjAxMzA2fDA&amp;ixlib=rb-4.0.3&amp;w=1400"
        feature1Description="One Demo Class For Free"
      ></Features20>
      <Features191
        cost="INR 9,997"
        cost2="INR 1,997"
        description2="Business Mastery, Business Strategies, Money Mastery, Time Management, Leadership, Rich Mindset, Evolution of Currency. One Class Each"
        description3="Each Class"
        feature1Title="Business Mastery"
        rootClassName="features191-root-class-name"
        feature1ImageSrc="https://images.unsplash.com/photo-1665686310429-ee43624978fa?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fEVudHJlcHJlbmV1cnxlbnwwfHx8fDE3MjEyMjY3NzZ8MA&amp;ixlib=rb-4.0.3&amp;w=1400"
      ></Features191>
      <Features201
        cost2="Like, Share, Follow &amp; Subscribe"
        description2="Learn About Entrepreneur"
        description3="Learn How to Manage Time Properly"
        feature1Title="Live Entreprenuer Interaction(LEI) For student"
        rootClassName="features201-root-class-name"
        feature1Action1="Register Now"
        feature1ImageSrc="https://images.unsplash.com/photo-1563986768817-257bf91c5753?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDcxfHxFbnRyZXByZW5ldXJ8ZW58MHx8fHwxNzIxMjI2Nzk4fDA&amp;ixlib=rb-4.0.3&amp;w=1400"
        feature1Description="Learn About Business Strategies"
        description4="Learn How to Become Leader"
        description5="Learn About Rich Mindset of a Person"
      ></Features201>
      <Features192
        cost="INR 9,997"
        cost2=""
        description2="Teach effective lead generation strategies."
        description3="Develop strategic marketing plans."
        description4="Enhance sales techniques for better closing rates. "
        feature1Title="Sales & Marketing"
        feature1Action1="Enroll Now"
        feature1ImageSrc="/sale.jpg"
        rootClassName="features192-root-class-name"
      ></Features192>
      <Features22 
      heading1="More Courses"
      content1="Broaden Your Horizons with Our Extra Courses"
      feature1Title="Business Strategies"
      feature1ImageAlt="Image"
      feature1ImageSrc="https://cdn.pixabay.com/photo/2024/02/08/02/53/digital-8560051_1280.jpg"
      feature1Description="This courses covers strategic planning, competitive analysis, growth tactics, innovation, & decision-making process."
      feature2Title="Time Management"
      feature2ImageAlt="Image"
      feature2ImageSrc="https://cdn.pixabay.com/photo/2020/07/08/05/37/stopwatch-5382626_1280.jpg"
      feature2Description="Enhance productivity with proven techniques to priotize tasks, manage time efficiently, & achieve work-life balance."
      feature3Title="Leadership"
      feature3ImageAlt="Image"
      feature3ImageSrc="https://emeritus.org/in/wp-content/uploads/sites/3/2022/06/what-is-leadership-768x432.jpg.optimal.jpg"
      feature3Description="Develop leadership skills to inspire teams, drive innovation, & lead organizations effectively in any industry."
      feature4Title="Money Mastery"
      feature4ImageAlt="Image"
      feature4ImageSrc="https://cdn.pixabay.com/photo/2024/08/03/20/20/ai-generated-8943064_1280.jpg"
      feature4Description="This course covers about personal fiinance, investment  strategies, wealth building budgeting, & financial independence."
      feature5Title="Rich Mindset"
      feature5ImageAlt="Image"
      feature5ImageSrc="https://cdn.pixabay.com/photo/2015/04/28/07/54/mindset-743165_1280.jpg"
      feature5Description="This course focuses in the weealth-building habits,financial psychology, goal setting & entrepreneurial thinking."
      feature6Title="Software"
      feature6ImageAlt="Image"
      feature6ImageSrc="https://cdn.pixabay.com/photo/2024/06/22/18/09/ai-generated-8846759_1280.jpg"
      feature6Description="This course cover basics about programming, software development, HTML, CSS, & JavaScript."
      feature7Title="German Language"
      feature7ImageAlt="Image"
      feature7ImageSrc="https://cdn.pixabay.com/photo/2012/11/06/04/06/german-64270_1280.jpg"
      feature7Description="This course cover the basics on speaking,  listening, reading, writing, grammar, & cultural understanding"
      feature8Title="Entrepenuer"
      feature8ImageAlt="Image"
      feature8ImageSrc="https://cdn.pixabay.com/photo/2024/06/06/06/49/business-8811978_960_720.jpg"
      feature8Description="This course covers business creation, innovation, startup, funding, risk management, and leadership skills."
      feature9Title= "AutoCAD Training"
      feature9Description="'The AutoCAD course teaches 2D & 3D drafting, design, modeling techniques, tools, & industry-specific applications."
      feature9ImageAlt= "Img"
      feature9ImageSrc= "https://cdn.pixabay.com/photo/2022/06/04/19/20/home-7242631_1280.jpg"
      feature10Title= "Astrology & Life"
      feature10Description= "This course explores zodiac signs, planetary influences, birth charts, & their imppact on the life decisions."
      feature10ImageAlt= "Img"
      feature10ImageSrc= "https://cdn.pixabay.com/photo/2019/12/20/20/13/zodiac-4709196_1280.png"
      feature11Title= "Fashion Designer"
      feature11Description= "This course covers design principles, fashion illustration, pattern making, garment construction, & trend forcasting."
      feature11ImageAlt= "Img"
      feature11ImageSrc= "https://cdn.pixabay.com/photo/2024/07/14/11/30/ai-generated-8894172_1280.jpg"
      feature12Title= "Interior Designer"
      feature12Description= "The interior designer course focuses on space planning, color theory, furniture design, material selection, & decor styles."
      feature12ImageAlt= "Img"
      feature12ImageSrc= "https://cdn.pixabay.com/photo/2017/12/11/13/24/interior-3012218_1280.png"
      rootClassName="features22-root-class-name"
      ></Features22>
      <Pricing8></Pricing8>
      <Footer1 rootClassName="footer1-root-class-name"></Footer1>
    </div>
  )
}

export default Courses
