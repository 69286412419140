import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './pricing8.css'

const Pricing8 = (props) => {
  const [isMonthly, setIsMonthly] = useState(true)
  return (
    <div id="Sale" className="pricing8-pricing23 thq-section-padding">
      <div className="pricing8-max-width thq-section-max-width">
        <div className="pricing8-section-title">
          <h1 className="pricing8-text thq-heading-1">{props.content1}</h1>
          <div className="pricing8-content">
            <h2 className="pricing8-text01 thq-heading-2">{props.heading1}</h2>
            <p className="pricing8-text02 thq-body-large">{props.content2}</p>
          </div>
        </div>
        <div className="pricing8-tabs">
          <a className="pricing8-button02 thq-button-animated thq-button-filled">
            <span className="thq-body-small">Sales & Marketing Membership</span>
          </a>
        </div>
        <div className="pricing8-container">
          <div className="pricing8-column thq-card">
              <svg viewBox="0 0 1024 1024" className="pricing8-icon">
                <path d="M853.333 853.333h-682.667c-70.571 0-128-57.429-128-128v-426.667c0-70.571 57.429-128 128-128h682.667c70.571 0 128 57.429 128 128v426.667c0 70.571-57.429 128-128 128zM170.667 256c-23.509 0-42.667 19.157-42.667 42.667v426.667c0 23.509 19.157 42.667 42.667 42.667h682.667c23.509 0 42.667-19.157 42.667-42.667v-426.667c0-23.509-19.157-42.667-42.667-42.667h-682.667z"></path>
                <path d="M426.667 640h-170.667c-23.595 0-42.667-19.115-42.667-42.667s19.072-42.667 42.667-42.667h170.667c23.595 0 42.667 19.115 42.667 42.667s-19.072 42.667-42.667 42.667z"></path>
                <path d="M426.667 469.333h-170.667c-23.595 0-42.667-19.115-42.667-42.667s19.072-42.667 42.667-42.667h170.667c23.595 0 42.667 19.115 42.667 42.667s-19.072 42.667-42.667 42.667z"></path>
                <path d="M768 448c0 47.128-38.205 85.333-85.333 85.333s-85.333-38.205-85.333-85.333c0-47.128 38.205-85.333 85.333-85.333 47.128 0 85.333 38.205 85.333 85.333z"></path>
                <path d="M682.667 569.856c-66.645 0-106.667 30.507-106.667 60.971 0 15.232 40.021 30.507 106.667 30.507 62.549 0 106.667-15.232 106.667-30.507 0-30.464-41.813-60.971-106.667-60.971z"></path>
              </svg>
              <div className="pricing8-price">
                <div className="pricing8-price01">
                  <p className="pricing8-text07 thq-body-large">
                    {props.plan1}
                  </p>
                  <h3 className="pricing8-text08 pricing8-heading-3">
                    {props.plan1Price}
                  </h3>
                </div>
                <div className="pricing8-list">
                  <div className="pricing8-list-item">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan1Feature1}
                    </span>
                  </div>
                  <div className="pricing8-list-item01">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan1Feature2}
                    </span>
                  </div>
                  <div className="pricing8-list-item02">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan1Feature3}
                    </span>
                  </div>
                </div>
              </div>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScOHlBkDhhKBX9-5h-1TvvX9WPvp9JgHGhq6kvi9l0i-lq3aw/viewform?usp=sf_link"
                target="_blank"
                rel="noreferrer noopener"
                className="pricing8-button02 thq-button-animated thq-button-filled"
              >
                <span className="thq-body-small">{props.plan1Action}</span>
              </a>
          </div>
          <div className="pricing8-column1 thq-card">
              <svg viewBox="0 0 1024 1024" className="pricing8-icon12">
                <path d="M598 298v-84h-172v84h172zM854 298q34 0 59 26t25 60v128q0 34-25 60t-59 26h-256v-86h-172v86h-256q-36 0-60-25t-24-61v-128q0-34 25-60t59-26h170v-84l86-86h170l86 86v84h172zM426 682h172v-42h298v170q0 36-25 61t-61 25h-596q-36 0-61-25t-25-61v-170h298v42z"></path>
              </svg>
              <div className="pricing8-price02">
                <div className="pricing8-price03">
                  <p className="pricing8-text14 thq-body-large">
                    {props.plan2}
                  </p>
                  <h3 className="pricing8-text15 pricing8-heading-3">
                    {props.plan2Price}
                  </h3>
                </div>
                <div className="pricing8-list1">
                  <div className="pricing8-list-item03">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan2Feature1}
                    </span>
                  </div>
                  <div className="pricing8-list-item04">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan2Feature2}
                    </span>
                  </div>
                  <div className="pricing8-list-item05">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan2Feature3}
                    </span>
                  </div>
                </div>
              </div>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScOHlBkDhhKBX9-5h-1TvvX9WPvp9JgHGhq6kvi9l0i-lq3aw/viewform?usp=sf_link"
                target="_blank"
                rel="noreferrer noopener"
                className="pricing8-button02 thq-button-animated thq-button-filled"
              >
                <span className="thq-body-small">{props.plan2Action}</span>
              </a>
          </div>
          <div className="pricing8-column2 thq-card">
              <svg viewBox="0 0 1024 1024" className="pricing8-icon22">
                <path d="M768 640v86h-86v-86h86zM768 470v84h-86v-84h86zM854 810v-426h-342v86h86v84h-86v86h86v86h-86v84h342zM426 298v-84h-84v84h84zM426 470v-86h-84v86h84zM426 640v-86h-84v86h84zM426 810v-84h-84v84h84zM256 298v-84h-86v84h86zM256 470v-86h-86v86h86zM256 640v-86h-86v86h86zM256 810v-84h-86v84h86zM512 298h426v598h-852v-768h426v170z"></path>
              </svg>
              <div className="pricing8-price04">
                <div className="pricing8-price05">
                  <p className="pricing8-text22 thq-body-large">
                    {props.plan3}
                  </p>
                  <h3 className="pricing8-text23 pricing8-heading-3">
                    {props.plan3Price}
                  </h3>
                </div>
                <div className="pricing8-list2">
                  <div className="pricing8-list-item07">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan3Feature1}
                    </span>
                  </div>
                  <div className="pricing8-list-item08">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan3Feature2}
                    </span>
                  </div>
                  <div className="pricing8-list-item09">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan3Feature3}
                    </span>
                  </div>
                </div>
              </div>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScOHlBkDhhKBX9-5h-1TvvX9WPvp9JgHGhq6kvi9l0i-lq3aw/viewform?usp=sf_link"
                target="_blank"
                rel="noreferrer noopener"
                className="pricing8-button02 thq-button-animated thq-button-filled"
              >
                <span className="thq-body-small">{props.plan3Action}</span>
              </a>
          </div>
          <div className="pricing8-column3 thq-card">
              <svg viewBox="0 0 1024 1024" className="pricing8-icon34">
                <path d="M768 640v86h-86v-86h86zM768 470v84h-86v-84h86zM854 810v-426h-342v86h86v84h-86v86h86v86h-86v84h342zM426 298v-84h-84v84h84zM426 470v-86h-84v86h84zM426 640v-86h-84v86h84zM426 810v-84h-84v84h84zM256 298v-84h-86v84h86zM256 470v-86h-86v86h86zM256 640v-86h-86v86h86zM256 810v-84h-86v84h86zM512 298h426v598h-852v-768h426v170z"></path>
              </svg>
              <div className="pricing8-price04">
                <div className="pricing8-price05">
                  <p className="pricing8-text22 thq-body-large">
                    {props.plan4}
                  </p>
                  <h3 className="pricing8-text23 pricing8-heading-3">
                    {props.plan4Price}
                  </h3>
                </div>
                <div className="pricing8-list2">
                  <div className="pricing8-list-item07">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan4Feature1}
                    </span>
                  </div>
                  <div className="pricing8-list-item08">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan4Feature2}
                    </span>
                  </div>
                  <div className="pricing8-list-item09">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan4Feature3}
                    </span>
                  </div>
                </div>
              </div>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScOHlBkDhhKBX9-5h-1TvvX9WPvp9JgHGhq6kvi9l0i-lq3aw/viewform?usp=sf_link"
                target="_blank"
                rel="noreferrer noopener"
                className="pricing8-button02 thq-button-animated thq-button-filled"
              >
                <span className="thq-body-small">{props.plan4Action}</span>
              </a>
          </div>
        </div>
      </div>
    </div>
  )
}

Pricing8.defaultProps = {
  content1: 'Membership plan',
  heading1: 'Why choose Amit World Academy',
  content2: 'Expertise in every fields, Tailored Solutions to meet your requirement, Commitment to help you succeed & improve, and Comprehensive Support.',
  plan1: 'Monthly Plan',
  plan1Price: 'INR 9,997',
  plan1Feature1: 'Personal Guidance from Expert.',
  plan1Feature2: '2 day online or virtual & 1 day face to face meeting in a week for a month.',
  plan1Feature3: 'Dinning with mentor once a month.',
  plan1Action: 'Register Now',
  plan2: 'Quarterly Plan',
  plan2Price: 'INR 24,997',
  plan2Feature1: 'Personal Guidance from Expert.',
  plan2Feature2: '2 day online or virtual & 1 day face to face meeting in a week for 3 month.',
  plan2Feature3: 'Dinning with mentor once a month.',
  plan2Action: 'Register Now',
  plan3: 'Half-yearly Plan',
  plan3Price: 'INR 49,997',
  plan3Feature1: 'Personal Guidance from Expert.',
  plan3Feature2: '2 day online or virtual & 1 day face to face meeting in a week for half a year.',
  plan3Feature3: 'Dinning with mentor once a month.',
  plan3Action: 'Register Now',
  plan4: 'Yearly Plan',
  plan4Price: 'INR 99,997',
  plan4Feature1: 'Personal Guidance from Expert.',
  plan4Feature2: '2 day online or virtual & 1 day face to face meeting in a week for a year.',
  plan4Feature3: 'Dinning with mentor once a month.',
  plan4Action: 'Register Now',
}

Pricing8.propTypes = {
  content1: PropTypes.string,
  heading1: PropTypes.string,
  content2: PropTypes.string,
  plan1: PropTypes.string,
  plan1Price: PropTypes.string,
  plan1Feature1: PropTypes.string,
  plan1Feature2: PropTypes.string,
  plan1Feature3: PropTypes.string,
  plan1Action: PropTypes.string,
  plan2: PropTypes.string,
  plan2Price: PropTypes.string,
  plan2Feature1: PropTypes.string,
  plan2Feature2: PropTypes.string,
  plan2Feature3: PropTypes.string,
  plan2Action: PropTypes.string,
  plan3: PropTypes.string,
  plan3Price: PropTypes.string,
  plan3Feature1: PropTypes.string,
  plan3Feature2: PropTypes.string,
  plan3Feature3: PropTypes.string,
  plan3Action: PropTypes.string,
  plan4: PropTypes.string,
  plan4Price: PropTypes.string,
  plan4Feature1: PropTypes.string,
  plan4Feature2: PropTypes.string,
  plan4Feature3: PropTypes.string,
  plan4Action: PropTypes.string,
}

export default Pricing8
